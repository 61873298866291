export interface CountryType {
    code: string;
    flagCode: string;
    default?: boolean;
}
export const Countries: readonly CountryType[] = [
    { code: 'de', flagCode: 'de' },
    { code: 'en', flagCode: 'gb', default: true },
]

