import React from 'react'
import {CircularProgress, Stack} from "@mui/material";

const LoadingPage = () => {
    return ( <Stack
            spacing={2}
            direction="column"
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "calc(100vh - 7.5rem)",
            }}
        >
        <CircularProgress size="5rem" color="primary"  />
        </Stack>
    );
}

export default LoadingPage;
