import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Auth0ContextInterface, useAuth0} from '@auth0/auth0-react';
import configuration from "../../package.json";
import {useAppSelector} from "../store/hooks";

interface ResponseData<T> {
    data: T;
}

interface ErrorData {
    message: string;
}

class AxiosRequestError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'AxiosRequestError';
    }
}

const useAuthenticatedRequest = () => {
    const { getAccessTokenSilently }: Auth0ContextInterface = useAuth0();
    const company = useAppSelector((state) => state.companies);

    const sendRequest = async <T>(config: AxiosRequestConfig): Promise<ResponseData<T>> => {
        try {
            const accessToken = await getAccessTokenSilently();
            const authConfig: AxiosRequestConfig = {
                ...config,
                headers: {
                    ...config.headers,
                    // @ts-ignore
                    'CompanyId': company.selectedCompany,
                    Authorization: `Bearer ${accessToken}`,
                },
                baseURL: configuration.backendUrl,
            };

            const response: AxiosResponse<T> = await axios(authConfig);
            return { data: response.data };
        } catch (error) {
            throw handleAxiosError(error);
        }
    };

    const handleAxiosError = (error: any): AxiosRequestError => {
        if (axios.isAxiosError(error)) {
            const axiosError = error as { response?: { data?: ErrorData } };
            const errorMessage = axiosError?.response?.data?.message || 'An error occurred';
            return new AxiosRequestError(errorMessage);
        } else {
            return new AxiosRequestError('An error occurred while making the request');
        }
    };

    return { sendRequest };
};

export default useAuthenticatedRequest;

