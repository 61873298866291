import React, {FC, ReactNode} from "react";
import {useContext, useEffect} from "react";
import {Outlet, useLocation} from "react-router-dom";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import {Auth0Context} from "@auth0/auth0-react";
import LayoutContent from "./LayoutContent";
import configuration from "../../package.json";
import {useAppDispatch} from "../store/hooks";
import {setSelectedCompany} from "../store/slices/companiesSlice";
import useAuthenticatedRequest from "../helpers/SendAuthenticatedRequest";

interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  return <MainInnerLayout children={children} />;
};

const MainInnerLayout: FC<MainLayoutProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { sendRequest } = useAuthenticatedRequest();
  const location = useLocation();

  const { user, isAuthenticated, loginWithRedirect, isLoading} = useContext(Auth0Context);
  const fetchCompanies = async () => {
    try {
      const response = await sendRequest<{ message: string }>({
        method: "GET",
        url: "Companies",
      });
      // @ts-ignore
      await dispatch(setSelectedCompany(response.data[0]?.id));
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    fetchCompanies().then();
  }, []);

  const login = async () => {
    if (!isLoading && !user) {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: {
            returnTo: `${location.pathname}${location.search}`,
          },
          authorizationParams: {
            audience: configuration.auth0.audience
          }
        });
      }
    }
  };

  useEffect(() => {
        login().then();
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [isLoading, isAuthenticated]);

  return (
    <Box>
      {isAuthenticated && (<LayoutContent children={children || <Outlet />} />)}
    </Box>
  );
};
MainLayout.propTypes = {
  children: PropTypes.node,
};
export default MainLayout;
