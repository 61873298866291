import {combineReducers, configureStore} from '@reduxjs/toolkit'
import companySlice from "./slices/companiesSlice";

const store = configureStore({
    reducer: combineReducers({
        companies: companySlice,
    })
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store;
