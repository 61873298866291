import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {useTranslation} from "react-i18next";
import {Badge} from "@mui/material";
import {useLocation} from "react-router-dom";

interface IProps {
  translationKey: string;
  isOpen: boolean;
  icon: JSX.Element;
  badgeCount: number;
  onClick: () => void;
  route: string;
}
const defaultProps: Partial<IProps> = {
  badgeCount: 0,
};

const ListItemCustom = (props: IProps): JSX.Element => {
  const { translationKey, isOpen, icon, onClick, badgeCount, route } = props;
  const { t } = useTranslation();

  const location = useLocation();
  const selected = route === location.pathname;

  return (
    <ListItem
      key={t(translationKey)}
      disablePadding
      sx={{ display: "block" }}
    >
      <ListItemButton
          selected={selected}
        sx={{
          minHeight: 48,
          maxWidth: 256,
          justifyContent: isOpen ? "initial" : "center",
          px: 2.5,
        }}
        onClick={onClick}
      >
        <ListItemIcon
          color={"primary"}
          sx={{
            minWidth: 0,
            mr: isOpen ? 1 : "auto",
            justifyContent: "center",
          }}
        >
          <Badge
            badgeContent={badgeCount}
            color="secondary"
            style={{ transform: "translate(25px, -1px)" }}
          />
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={t(translationKey)}
          primaryTypographyProps={{
            variant: "subtitle2",
            style: {
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            },
          }}
          sx={{ opacity: isOpen ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  );
};
ListItemCustom.defaultProps = defaultProps;
export default ListItemCustom;
