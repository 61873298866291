import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface CompanyState {
    selectedCompany: string;
}

const initialState: CompanyState = {
    selectedCompany: ""
}

export const companySlice = createSlice({
    name: "companies",
    initialState,
    reducers: {
        setSelectedCompany(state, action: PayloadAction<string>) {
            state.selectedCompany = action.payload
        }
    },
    extraReducers: (builder) => {}
});

export const { setSelectedCompany} = companySlice.actions;
export default companySlice.reducer;
