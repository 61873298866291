import "./instrument";
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import TranslationDE from "./translations/de.json";
import TranslationEN from "./translations/en.json";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { Auth0ProviderWithNavigate } from "./auth/auth0-provider-with-history";
import App from "./App";

const theme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        h1: {
          fontSize: "1.25rem",
          fontWeight: "bold",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#424242",
          color: "#ffffff",
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        gutters: {
          backgroundColor: "#424242",
          color: "#ffffff",
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            backgroundColor: "#121212",
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "& .MuiPaper-root": {
            backgroundColor: "#121212",
          },
        },
        gutters: {
          "&:hover": {
            backgroundColor: "rgba(30, 30, 30) !important",
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(0, 212, 190,.6)",
          },
        },
      },
    },
  },
  palette: {
    mode: "dark",
    background: {
      default: "#1e1e1e",
      paper: "#1e1e1e",
    },
    primary: {
      main: "#00d4be",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#ffd100",
      contrastText: "#00d4be",
    },
    text: {
      primary: "#ffffff",
      secondary: "#ffffff",
      disabled: "#00d4be",
    },
  },
});

const resources = {
  de: { translation: TranslationDE },
  en: { translation: TranslationEN },
};

i18next.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  lowerCaseLng: true,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
