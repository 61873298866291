import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import configuration from "../package.json";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router-dom";

if (configuration.environment !== 'local') {
    Sentry.init({
        dsn: "https://0a54c65ede4a8ac9955ffa6589662e0f@o4507939807625216.ingest.de.sentry.io/4507940075864144",
        environment: configuration.environment,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
            Sentry.replayIntegration(),
        ],
        tracesSampleRate: 0.1,
        tracePropagationTargets: [configuration.backendUrl],
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });

}
